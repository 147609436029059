/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

@tailwind base;
@tailwind components;
@tailwind utilities;

.center-flex {
  @apply flex justify-center items-center;
}
.btn-increase-decrease {
  @apply border w-16 h-10 pb-10 leading-none bg-[#5C7AFF]
    text-skin-purple font-bold text-4xl rounded-lg;
}

.btn-normal {
  @apply mt-2 py-1 px-5 bg-[#705D56] text-[#eed87f]
  font-bold text-lg rounded-lg mb-5;
}

@layer base {
  :root {
    --c-green: #45503b;
    --c-pink: #e5989b;
    --c-purple: #3c1b43;
    --c-white: #fbfffe;
    --c-gold: #e0be36;

    --color-with-opacity: 23, 43, 22;
  }
  /* add below class to the parent to have all childs have below theme */
  .theme-one {
    --color-text-base: #0000ff;
  }
}

table,
tr,
th,
td {
  border-collapse: collapse;
  border: 1px solid black;
  padding: 5px;
  text-align: center;
}

/* :root {
  --c-green: #45503bff;
  --c-pink: #e5989bff;
  --c-header-bg: #3c1b43ff;
  --c-white: #fbfffeff;
  --c-header-text: #e0be36ff;
} */
